import React from 'react';

export const Wellbeing = ({className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1"  x="0px" y="0px" viewBox="-5.0 -13.0 110.0 135.0"  className={className}>
    <g>
      <path d="m66.469 17.305c0-4.3711-1.7344-8.5586-4.8242-11.648-3.0859-3.0859-7.2773-4.8242-11.645-4.8242s-8.5586 1.7383-11.645 4.8242c-3.0898 3.0898-4.8242 7.2773-4.8242 11.648 0 4.3672 1.7344 8.5547 4.8242 11.645 3.0859 3.0898 7.2773 4.8242 11.645 4.8242 4.3672-0.003907 8.5508-1.7422 11.641-4.8281 3.0859-3.0898 4.8242-7.2773 4.8281-11.641zm-27.941 0h0.003906c0-4.6406 2.793-8.8242 7.0781-10.598 4.2891-1.7773 9.2227-0.79297 12.5 2.4844 3.2812 3.2812 4.2617 8.2148 2.4883 12.5-1.7773 4.2891-5.957 7.082-10.598 7.082-6.332-0.007813-11.461-5.1367-11.469-11.469z"/>
      <path d="m21.176 40.699c-6.1367 3.7227-9.9023 10.359-9.9609 17.539 0 25.668 32.922 40.93 38.785 40.93 5.8594 0 38.785-15.27 38.785-40.941v-0.003906c-0.015625-5.6797-2.4062-11.098-6.5977-14.934-4.2734-3.9609-9.8906-6.1445-15.719-6.1055-6.1875-0.039063-12.125 2.4258-16.469 6.832-3.7305-3.7148-8.6055-6.0625-13.836-6.6641-5.2305-0.60156-10.512 0.57813-14.988 3.3477zm26.789 8.668c0.49609 0.62109 1.2422 0.98047 2.0352 0.98047s1.5391-0.35938 2.0352-0.98047c3.375-4.5742 8.7422-7.2461 14.43-7.1758 4.5547-0.039062 8.957 1.6602 12.301 4.7539 3.1719 2.8984 4.9844 6.9883 5 11.285 0.019531 21.855-29.398 35.938-33.766 35.938s-33.785-14.074-33.785-35.934c0.066406-5.4531 2.9453-10.484 7.6172-13.297 2.9062-1.8125 6.2656-2.7695 9.6914-2.7578 5.6875-0.066407 11.062 2.6055 14.441 7.1797z"/>
    </g>
  
  </svg>
  )
}

export default Wellbeing;
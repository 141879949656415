import React from 'react';

function Plans() {
  return (
    <div>
      <h2>Join the Tidy Toys Community Today!</h2>
        Simplify playtime, prioritize sustainability, and reclaim precious moments with your family. Subscribe to Tidy Toys now and experience the joy of hassle-free, eco-friendly playtime delivered straight to your doorstep.
    </div>
        
  );
}

export default Plans;
import React from 'react';

export const Convenience = ({className}) => {
 return( <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={className}>
    <g>
      <path d="M23.565,29.277a3.568,3.568,0,0,0-5.046,5.046l8.767,8.767a3.568,3.568,0,0,0,5.046,0L45.481,29.94a3.566,3.566,0,0,0,0-5.044,3.65,3.65,0,0,0-5.046,0L29.808,35.521ZM41.849,26.31a1.6,1.6,0,0,1,2.218,0,1.567,1.567,0,0,1,0,2.216L30.919,41.675a1.606,1.606,0,0,1-2.219,0l-8.767-8.767a1.568,1.568,0,0,1,2.218-2.218l6.95,6.951a1,1,0,0,0,1.414,0Z" />
      <path d="M45.412,16.146a3,3,0,0,0,.2-1.049,3.032,3.032,0,1,0-3.032,3.032,3,3,0,0,0,1.555-.443A20.335,20.335,0,1,1,30.21,13.75l-2.917,2.079a1,1,0,1,0,1.16,1.629l5.681-4.049a1,1,0,0,0-.009-1.636L28.48,7.854A1,1,0,1,0,27.34,9.5l3.191,2.217a22.343,22.343,0,1,0,14.881,4.433ZM41.546,15.1a1.032,1.032,0,1,1,1.032,1.032A1.033,1.033,0,0,1,41.546,15.1Z" />
    </g>
  </svg>)
};
export default Convenience;